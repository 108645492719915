import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars, faCog, faHome, faLink, faPencilSquare, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

class Aside extends Component {
    componentDidMount(){
toast.error("Falha na comunicação com o servidor!")
    }

    getClassNames = ({ isActive }) => (isActive ? 'rc-collapse-header selected' : 'rc-collapse-header');
  render() {
    return (
      <>
 
 <aside class="sc-390cf866-0 dKhYLg">
 <ul class="sc-390cf866-1 bARNXv">
   <h3>Categorias</h3>
   <div class="rc-collapse" role="tablist">

      <li class="rc-collapse-item">
    
         <NavLink exact="true" className={this.getClassNames} to="/" class="rc-collapse-header">
            <span class="rc-collapse-header-text">
               <div class="sc-bcXHqe sc-gswNZR kzUpfE bbLVwL"><FontAwesomeIcon icon={faHome}/> Dashboard</div>
            </span>
         </NavLink>
      </li>

      <li class="rc-collapse-item">
      <NavLink exact="true" className={this.getClassNames} to="/links" class="rc-collapse-header">
           <span class="rc-collapse-header-text">
               <div class="sc-bcXHqe sc-gswNZR kzUpfE bbLVwL"><FontAwesomeIcon icon={faLink}/> Links</div>
            </span>
         </NavLink>
      </li>

      <li class="rc-collapse-item">
      <NavLink exact="true" className={this.getClassNames} to="/aparencia" class="rc-collapse-header">
            <span class="rc-collapse-header-text">
               <div class="sc-bcXHqe sc-gswNZR kzUpfE bbLVwL"><FontAwesomeIcon icon={faPencilSquare}/>Aparência</div>
            </span>
         </NavLink>
      </li>


      <li class="rc-collapse-item">
      <NavLink exact="true" className={this.getClassNames} to="/configuracao" class="rc-collapse-header">
   
              <span class="rc-collapse-header-text">
               <div class="sc-bcXHqe sc-gswNZR kzUpfE bbLVwL"><FontAwesomeIcon icon={faCog}/>Configurações</div>
            </span>
         </NavLink>
      </li>


   </div>
</ul>
    
    <div className='sair' onClick={()=>{
         document.cookie = 'acess_token' + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
         window.location = '/'
    }}>

<div>  <FontAwesomeIcon icon={faSignOut}/>  Sair</div>
  
    </div>
 


</aside>


      </>
    );
  }
}

export default Aside;