import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import "./h.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faClose } from '@fortawesome/free-solid-svg-icons';

class HeaderDash extends Component {
  constructor(props) {
    super(props);
    this.state = {
     OpenMenu:false,
    };
  }
  getClassNames = ({ isActive }) => (isActive ? 'active' : '');

  render() {
    return (
      <>
  
  <header class="sc-277ce8cb-1 fznaMn">
    <div class="sc-277ce8cb-2 fOCdQW">
       <div class="sc-bcXHqe sc-gswNZR htPSqG bbLVwL section header-section left">
          <div class="sc-d4f84a7-0 gnDYAQ"><Link class="" title="10015 Tools Home" to="/">Linkes.bio</Link></div>
       </div>
       <div class="sc-bcXHqe sc-gswNZR gjkaQD bbLVwL section header-section mid">

       </div>
       <div class="sc-bcXHqe sc-gswNZR sc-277ce8cb-3 bqOpTS bbLVwL section header-section right">
          <div class="sc-bcXHqe sc-gswNZR kslGQD bbLVwL">
             <nav class="sc-277ce8cb-0 jkkBkE">
                <a class="product-finder" href="/product-finder">
                   <div class="label">Product Finder</div>
                   <div class="badge">βETA</div>
                </a>
             </nav>
             <div class="sc-bcXHqe sc-gswNZR sc-8ebbe9da-0 kzUpfE bbLVwL bZZmFg tablet-hidden">
                <div class="sc-bcXHqe sc-gswNZR kzUpfE bbLVwL menu-item-title">Categories<i class="bx bx-chevron-down"></i></div>
                  </div>
             <div class="sc-bcXHqe sc-gswNZR sc-8ebbe9da-0 kzUpfE bbLVwL bZZmFg tablet-hidden">
                        </div>
             <div class="sc-bcXHqe sc-gswNZR sc-4fa535bf-0 bGaasd bbLVwL eTinNf">
                <i class="bx bx-menu-alt-right"></i>
                <div class="sc-bcXHqe dQHGwq tablet-hidden">Menu</div>
             </div>
             <div class="sc-bcXHqe hHgGmU tablet-hidden">
                <a class="sc-ed7ce5bb-0 iqxPPW buy-me-a-coffee-icon" target="_blank" href="https://www.buymeacoffee.com/fatihtelis" title="Buy Me A Coffee" rel="noopener noreferrer nofollow">
                 
                </a>
             </div>
             <div class="sc-bcXHqe hHgGmU tablet-hidden">
                <div class="sc-bcXHqe sc-gswNZR sc-3bd5624e-0 kzUpfE bbLVwL gdvIzy">
                   <button id="sign-in-desktop" type="button" class="sign-in-button" aria-label="Sign In">
                      
                      <div class="sign-in">
                        <FontAwesomeIcon icon={faBell}/>
                      </div>
                      <i class="bx bx-log-in-circle mobile-sign-in-icon"></i>
                      <div class="sc-b4df0171-0 jLqXrx"></div>
                   </button>
                </div>
             </div>
          </div>
       </div>
       <div class="sc-bcXHqe sc-gswNZR sc-277ce8cb-4 ihRWtT bbLVwL kfhbjy section">
          <div height="100%" class="sc-bcXHqe fqNkap"><button type="button" aria-label="Search" class="sc-33cc82a-0 kBmvFA"><i class="sc-33cc82a-1 eHFHvR bx bx-search"></i></button></div>
          <div height="100%" class="sc-bcXHqe fqNkap">
             <div class="sc-bcXHqe sc-gswNZR sc-3bd5624e-0 kzUpfE bbLVwL gdvIzy">
                <button id="sign-in-mobile" type="button" class="sign-in-button" aria-label="Sign In">
                   <div class="sign-in">Sign ins</div>
                   <i class="bx bx-log-in-circle mobile-sign-in-icon"></i>
                   <div class="sc-b4df0171-0 jLqXrx"></div>
                </button>
             </div>
          </div>
       </div>
    </div>
 </header>
 
      
      </>
    );
  }
}

export default HeaderDash;