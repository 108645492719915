import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import "./d.css"
import { toast } from 'react-toastify';
class Dashboard extends Component {
    componentDidMount(){
 document.title = "DashBoard | LinKes.bio"

    }
  render() {
    return (
      <>
         <div class="pg-l">
     <div class="spinner"></div>
     </div>
      </>
    );
  }
}

export default Dashboard;