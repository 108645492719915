import React, { Component } from 'react';
import { Link } from "react-router-dom";
import "./style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import analyse from "../../img/link.svg";
import celular from "./devices.svg";
import sis from "./sis.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faDollar,
  faBoxOpen,
  faChartBar,
  faStore,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import Header from '../../componets/Header';

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
     username:''
    };
  }
  componentDidMount(){
    document.title = "Linkes.bio - Todos os Seus Links em um Só Lugar"
  }
  render() {
    return (
      <>
        <section className="hero-header primary-header slider-header" id="home">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="hero-header-content">
                  <h1>Todos os Seus Links em Um Só Lugar!</h1>
                  <p>
                  Crie sua biolink em alguns minutos e unifique toda a sua presença online de forma simples e do seu jeito com imagens, vídeos, pixels e links relevantes para o seu público. 
                  </p>
                  <div className="input-form">
                    <div className="ipt-nome-usr">
<div className='url'>
 <p>Linkes.bio/</p>
<input type='text' onChange={(e)=>{
this.setState({username:e.target.value})
}} placeholder='Seu nome'/>
</div>
                 

                      <button className='btn-ini' onClick={()=>{
                        this.props.navigate('/cadastre-se')
                       
                      }}>Criar conta</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-12">
                <div className="hero-header-image">
                  <img src={analyse} style={{ width: 643, height: 435 }} alt="#" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about-us" className="work section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title">
                  <h2>Transforme o link da sua Bio em uma página para divulgar todos os seus links</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work">
                  <div className="serial">
                    <span>1</span>
                  </div>
                  <h3>Crie sua conta</h3>
                  <p>Quando você se cadastra no Linkes.bio, uma página personalizada será automaticamente criada com o nome de usuário que você escolher.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work">
                  <div className="serial">
                    <span>2</span>
                  </div>
                  <h3>Insira seus links</h3>
                  <p>Crie links para qualquer destino desejado. Com cada novo link, você tem a liberdade de adicionar descrições atraentes e personalizar sua aparência para destacar e tornar suas informações ainda mais envolventes. </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work last">
                  <div className="serial">
                    <span>3</span>
                  </div>
                  <h3>Compartilhe sua página</h3>
                  <p>Divulgue sua página, receba visitas e cliques, e acompanhe o desempenho dentro do Linkes.bio</p>
                </div>
              </div>
           
            </div>
          </div>
        </section>

      </>
    );
  }
}

export default Inicio;