import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faDollar,
  faBoxOpen,
  faChartBar,
  faStore,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import img from "../../img/undraw_sign_in_re_o58h.svg"
class Cadastro extends Component {
    componentDidMount(){
              document.title = "Cadastro | Linkes.bio"
       
    }
  render() {
    return (
      <>
        <section class="page-lgn">
  <div class="container-fluid h-custom area" >
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-9 col-lg-6 col-xl-5">
        <img src={img} class="img-fluid"
          alt="Sample image"/>
      </div>
      <div class="col-md-8 col-lg-6 col-xl-5 offset-xl-1">
        <form>
          <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
       
            
          </div>

          

       
          <div class="form-outline mb-4">
            <input type="email" id="form3Example3" class="form-control form-control-lg"
              placeholder="Usuário:" />

          </div>

          <div class="form-outline mb-4">
            <input type="email" id="form3Example3" class="form-control form-control-lg"
              placeholder="E-mail:" />

          </div>


          <div class="form-outline mb-4">
            <input type="email" id="form3Example3" class="form-control form-control-lg"
              placeholder="Senha:" />

          </div>

          <div class="form-outline mb-4">
            <input type="email" id="form3Example3" class="form-control form-control-lg"
              placeholder="Confirme a senha:" />

          </div>
        

        

          <div class="text-center text-lg-start mt-4 pt-2">
            <button type="button" class="btn btn-primary btn-lg"
            >Enviar</button>
            <p class="small fw-bold mt-2 pt-1 mb-0">Já possui conta? <Link to="/entrar"
                class="link-danger">faça login</Link></p>
          </div>

        </form>
      </div>
    </div>
  </div>
  <div class="my-footer d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5" style={{background:"#512b78"}}>

    <div class="text-white mb-3 mb-md-0">
      Copyright © 2024. All rights reserved.
    </div>
   
    <div>
      <a href="#!" class="text-white me-4">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="#!" class="text-white me-4">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="#!" class="text-white me-4">
        <i class="fab fa-google"></i>
      </a>
      <a href="#!" class="text-white">
        <i class="fab fa-linkedin-in"></i>
      </a>
    </div>

  </div>
</section>
      </>
    );
  }
}

export default Cadastro;