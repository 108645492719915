import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import p404 from "../../../img/page404.svg"
class D404 extends Component {
    componentDidMount(){
 document.title = "Página não encontrada!"
    }
  render() {
    return (
      <>

   <div class="sc-52bcfac8-0 eqKVPw container sc-ec7d64aa-0 jlGJr page-container">
      <div class="sc-a1a28b93-0 kphhjc">
         <div class="sc-a1a28b93-1 dnrfAE">
           <img src={p404} style={{width:'100%'}}/>
         </div>
         <div style={{textAlign:'center'}}>
         <strong>Oops! página não encontra!</strong><br/>
         A página que você está procurando não existe. Pode ter sido removida ou excluída.</div>
         <Link title="10015 Tools Home" to="/">
            <button type="button" class="sc-f0c4711b-1 bUgJOQ button primary">
               <div class="button-content">
                  <i class="button-icon bx bx-home"></i>
                  <div class="button-text">Página inicial</div>
                  <div color="#ffffff" class="sc-b4df0171-0 jLqXrx"></div>
               </div>
            </button>
         </Link>
      </div>
   </div>
  


      </>
    );
  }
}

export default D404;